<template>
  <div class="agregador">
    <div class="card">
      <div class="header">
        <div class="header__esq">
          <div class="titulo">
            Identifica Pessoa Jurídica
            <span class="sumario" v-if="!this.resultado.erro && libera">
              <span>(</span>
              <span v-for="(param, index) in sumario" :key="param.Nome">
                {{ param.Nome }} : {{ param.Valor }}
                <span v-if="index != Object.keys(sumario).length - 1">,</span>
              </span>
              <span>)</span>
            </span>
          </div>
          <div class="subtitulo">Informações basicas de pessoa jurídica</div>
        </div>
        <div class="header__dir">
          <span class="codigo-agrupador">{{ codigo_agrupador }}</span>
          <div
            class="data"
            v-if="
              !this.resultado.erro && this.resultado.atributos != null && libera
            "
          >
            <span
              >{{
                contexto.resultados.BOXATRPJ001.sumario_consulta.data_transacao_brt
                  .slice(0, 10)
                  .split("-")
                  .reverse()
                  .join("/")
              }}
            </span>
            <span>
              {{
                contexto.resultados.BOXATRPJ001.sumario_consulta.data_transacao_brt.slice(
                  11,
                  19
                )
              }}</span
            >
          </div>
          <span class="material-icons icon">business_center</span>
        </div>
      </div>
      <div
        v-if="
          !this.resultado.erro && this.resultado.atributos != null && libera
        "
        class="corpo"
      >
        <div class="corpo__esq">
          <div>
            Razão social: <span class="dado">{{ razaoSocial }}</span>
          </div>
          <div v-if="nomeFantasia">
            Nome Fantasia: <span class="dado">{{ nomeFantasia }}</span>
          </div>
          <div>
            Data de início:
            <span class="dado">{{ dataInicio }}</span>
          </div>
          <div>
            Porte: <span class="dado">{{ porte }}</span>
          </div>
          <div v-if="situacaoCadastral" class="situ-cadastral">
            Situação Cadastral:
            <span class="dado">{{ situacaoCadastral }}</span>
            <span class="material-icons check">check_circle</span>
          </div>
          <div v-else class="situ-cadastral">
            Situação Cadastral:
            <span class="dado">{{ situacaoCadastral }}</span>
            <span class="material-icons">highlight_off</span>
          </div>
        </div>
        <div class="corpo__dir">
          <div class="cnae">
            Atividade econômica principal:
            <div class="desc">
              {{ descricaoCnae }} -
              <span class="dado"> {{ cnaeFiscal }} ({{ tipoCnae }}) </span>
            </div>
          </div>
          <div class="endereco">
            <div
              class="cep"
              v-if="!historico && quantidadeDeContextos == 1"
              @click="novaConsulta(endereco.cep)"
            >
              <div class="cep-valor">
                CEP: <span class="dado">{{ endereco.cep }}</span>

                <md-tooltip md-direction="right">
                  Dado disponível para realização de nova consulta.
                </md-tooltip>
                <span class="material-icons icon-next-consulta">near_me</span>
              </div>
            </div>
            <div class="cep" v-else>
              <div class="cep-valor">
                CEP: <span class="dado">{{ endereco.cep }}</span>
              </div>
            </div>
            <div class="desc">
              <div>
                {{ endereco.logradouro }}, {{ endereco.numero }} -
                {{ endereco.bairro }}
              </div>
              <div>{{ endereco.municipio }} - {{ endereco.uf }}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="resultado.atributos == null" class="aviso-vazio">
        Nenhum resultado encontrado
      </div>
      <div v-else-if="!libera">
        <Loading />
      </div>
      <div v-else-if="resultado.erro != null" class="corpo-nao-encontrado">
        {{ resultado.erro.descricao }}
      </div>

      <div class="corpo-inferior">
        <div
          class="alertas"
          :class="{ 'span-2': historico || produtosNaoConsultados.length == 0 }"
        >
          <div class="titulo">
            Alertas:
            <span class="material-icons"> error_outline </span>
          </div>

          <div class="line" />
          <div
            class="corpo-alertas"
            v-if="!loading_alertas && alertas.length != 0"
          >
            <div
              class="alerta-basic"
              :class="{ alerta: alerta.Recomendado && !historico }"
              v-for="alerta in alertas"
              :key="alerta.Nome"
              @click="consultaAlerta(alerta)"
            >
              <div class="bolinha">
                <div class="bolinha" :class="decideCorIndicador(alerta)"></div>
              </div>
              {{ alerta.Nome }}

              <span
                class="material-icons icon-next-consulta"
                v-if="alerta.Recomendado && !historico && permitido(alerta)"
                :class="decideCorIndicador(alerta)"
                >near_me</span
              >
            </div>
          </div>
          <div
            class="corpo-alertas-loading"
            v-else-if="alertas.length == 0 && !loading_alertas"
          >
            Não encontramos alertas para o CNPJ informado
          </div>
          <div class="corpo-alertas-loading" v-else>
            <Loading />
          </div>
        </div>
        <div
          class="extras"
          v-if="!historico && produtosNaoConsultados.length != 0"
        >
          <div class="titulo">Consultas Disponíveis:</div>
          <div class="line" />
          <div
            class="produtos"
            v-for="produto in produtosNaoConsultados"
            :key="produto"
            @click="addExtra(produto)"
          >
            <div class="bolinha">
              <div class="bolinha --extra"></div>
            </div>
            {{ listaDeProdutos.PJ[produto] }}
            <span class="material-icons icon-next-consulta --extra-icon"
              >near_me</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formataData, formataNome } from "@/helpers/utilitarios";
import { mascaras } from "@/helpers/mascaras";
import { consultasService } from "@/services";
import Loading from "@/components/Loading";
import alertasJSON from "@/helpers/alertasPJ.json";
import { alertasHelper } from "@/helpers/alertas.js";
import listaProdutos from "@/helpers/lista_produtos.json";
import { produtosStore } from "@/stores/produtos";
import { mapActions, mapWritableState } from "pinia";

export default {
  name: "BOXATRPJ001",
  components: {
    Loading,
  },
  props: {
    codigo_agrupador: String,
    campos: Object,
    contexto: Object,
    quantidadeDeContextos: Number,
    historico: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cnaeFiscal: "",
      tipoCnae: "",
      descricaoCnae: "",
      dataInicio: "",
      endereco: {
        cep: "",
        logradouro: "",
        complemento: "",
        numero: "",
        uf: "",
        bairro: "",
        municipio: "",
      },

      nomeFantasia: "",
      porte: "",
      razaoSocial: "",
      situacaoCadastral: "",
      anos: "",
      sumario: [],
      erro: false,
      resultado: {},
      libera: false,
      loading_alertas: true,
      alertas: [],
    };
  },
  async mounted() {
    if (this.contexto.resultados.BOXATRPJ001) {
      this.resultado = this.contexto.resultados.BOXATRPJ001;
      this.libera = true;
      if (!this.contexto.resultados.BOXATRPJ001.erro) {
        this.trataDados(this.contexto.resultados.BOXATRPJ001);
      } else {
        this.erro = true;
      }
      this.encontraSumario(this.contexto.resultados.BOXATRPJ001);
    } else {
      var objetoConsulta = {};
      this.contexto.inferido
        ? (objetoConsulta["cod_transacao_ciclica_pai"] =
            this.contexto.inferido["cod_transacao"])
        : "";
      this.campos["CNPJ"]
        ? (objetoConsulta["cpf_cnpj"] = this.campos["CNPJ"])
        : "";
      this.campos["DATA_REF"]
        ? (objetoConsulta["data_ref"] = this.campos["DATA_REF"])
        : "";
      this.campos["CEP"] ? (objetoConsulta["cep"] = this.campos["CEP"]) : "";
      objetoConsulta["cod_ref"] = "BOXATRPJ001";
      objetoConsulta["tipo_pessoa"] = "pessoa_juridica";
      objetoConsulta["modo_resultado"] = "codigo";
      objetoConsulta["cod_agrupador"] = this.codigo_agrupador;
      await consultasService
        .realizaConsulta(objetoConsulta)
        .then((resultado) => {
          this.resultado = resultado;
          this.contexto.resultados["BOXATRPJ001"] = { ...resultado };
        });
      this.libera = true;
      if (!this.resultado.erro) {
        this.trataDados(this.resultado);
      } else {
        this.erro = true;
      }
      this.encontraSumario(this.resultado);
    }

    //Faz consulta de alertas
    this.loading_alertas = true;

    if (this.contexto.resultados.BOXWRNPJ001) {
      this.alertas = alertasHelper.trataAlertas(
        this.contexto.resultados.BOXWRNPJ001,
        alertasJSON
      );
      this.loading_alertas = false;
    } else {
      const response = await alertasHelper.consultaAlertas(
        "BOXWRNPJ001",
        this.codigo_agrupador,
        this.campos
      );
      this.alertas = alertasHelper.trataAlertas(response, alertasJSON);
      this.contexto.resultados.BOXWRNPJ001 = response;
      this.loading_alertas = false;
    }
  },
  methods: {
    permitido(alerta) {
      if (alerta.Recomendado) {
        var permitido = false;
        this.produtos.forEach((produto) => {
          if (alerta.Recomendado == produto.Box) {
            permitido = true;
          }
        });
      }

      return permitido;
    },
    decideCorIndicador(alerta) {
      switch (alerta.Indicador) {
        case "Positivo":
          return "--positive";
        case "Negativo":
          return "--negative";
        case "Atencao":
          return "--warning";
      }
      return "";
    },
    async consultaAlerta(alerta) {
      if (!alerta.Recomendado || this.historico || !this.permitido(alerta)) {
        return;
      }
      if (await alertasHelper.executaAlerta(alerta, this.contexto)) {
        this.$emit("addRequisitado", alerta.Recomendado, this.contexto.id);
      }
    },

    trataDados(resultado) {
      this.cnaeFiscal = resultado.atributos.bk_201_001_cnae_fiscal;
      this.descricaoCnae =
        resultado.atributos.bk_201_001_descricao_cnae_subclasse;
      this.tipoCnae = resultado.atributos.bk_201_001_tipo_cnae_secao;
      this.dataInicio = formataData(
        resultado.atributos.bk_201_001_data_inicio_atividade
      );
      this.nomeFantasia = resultado.atributos.bk_201_001_nome_fantasia;
      this.porte = resultado.atributos.bk_201_001_porte;
      this.razaoSocial = resultado.atributos.bk_201_001_razao_social;
      this.situacaoCadastral =
        resultado.atributos.bk_201_001_situacao_cadastral;

      this.endereco.cep = resultado.atributos.bk_201_001_cep;
      this.endereco.logradouro = formataNome(
        resultado.atributos.bk_201_001_logradouro
      );
      this.endereco.complemento =
        resultado.atributos.bk_201_001_logradouro_complemento;
      this.endereco.numero = resultado.atributos.bk_201_001_logradouro_numero;
      this.endereco.uf = resultado.atributos.bk_201_001_aglomerado_uf;
      this.endereco.bairro = formataNome(resultado.atributos.bk_201_001_bairro);
      this.endereco.municipio = formataNome(
        resultado.atributos.bk_201_001_municipio
      );

      if (this.dataInicio) {
        this.anos = new Date().getFullYear() - this.dataInicio.slice(6);
      }
    },
    encontraSumario(resultado) {
      const sumario_consulta = resultado.sumario_consulta.parametros;
      if (sumario_consulta.cnpj) {
        this.sumario.push({
          Nome: "CNPJ",
          Valor: mascaras.cnpj(sumario_consulta.cnpj),
        });
      }
      if (sumario_consulta.cep) {
        this.sumario.push({
          Nome: "CEP",
          Valor: mascaras.cep(sumario_consulta.cpf),
        });
      }
    },
    novaConsulta(cep) {
      var obj = {};
      obj["cep"] = cep;
      obj["cod_transacao"] = this.resultado.sumario_consulta.cod_transacao;
      this.$emit("addConsulta", obj);
    },
    async addExtra(produto) {
      if (await alertasHelper.executaExtra(listaProdutos.PJ[produto], 1)) {
        this.$emit("addRequisitado", produto, this.contexto.id);
      }
    },
    ...mapActions(produtosStore, ["produtosAusentes"]),
  },
  computed: {
    ...mapWritableState(produtosStore, {
      produtos: "produtos",
    }),
    listaDeProdutos() {
      return listaProdutos;
    },
    produtosNaoConsultados() {
      return this.produtosAusentes(this.contexto.requisitados, "PJ");
    },
  },
};
</script>
<style lang="scss" src="@/styles/components/box_cabecalho.scss" scoped></style>

<style lang="scss" scoped>
.check {
  align-self: center;
  color: #16bb16;
  font-size: 1rem;
}

.corpo__dir {
  .cnae {
    @include flex-center(column);
    gap: 5px;
  }
  .endereco {
    width: 100%;
    @include flex-center(column);
    text-align: center;

    .cep {
      color: var(--accent);
      display: flex;
      cursor: pointer;
      text-align: center;
      justify-content: center;

      .cep-valor {
        @include flex-align-line();
        gap: 10px;
      }
    }
  }
  .desc {
    text-align: center;
    font-weight: 400;
    line-height: 1.2rem;
    font-style: italic;
  }
}

.aviso-vazio {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
}
</style>
